import React from 'react'

import Layout from '../components/Layout'
import { Title } from '../components/Typography'

const NotFoundPage: React.FC = () => (
  <Layout title="Not Found">
    <Title>ページが見つかりません</Title>
  </Layout>
)

export default NotFoundPage
