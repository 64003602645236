import React, { useEffect, useState } from 'react'
import axios from 'axios'
import BlogPost from './BlogPost'
import Layout from './Layout'
import NotFoundPage from '../pages/404'
import { LinearProgress } from '@material-ui/core'

type MicrocmsBlogResponse = {
  id: string
  createdAt: string
  updatedAt: string
  publishedAt: string
  title: string
  date: string
  categories: {
    id: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    name: string
  }[]
  thumbnail: { url: string }
  body: string
}

type Props = { contentId?: string; draftKey?: string }

const BlogPreviewComponent: React.FC<Props> = ({ contentId, draftKey }) => {
  const [data, setData] = useState<MicrocmsBlogResponse | undefined>(undefined)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!contentId) {
      setError(true)
      return
    }

    const endPoint = new URL(
      `https://${process.env.GATSBY_MICROCMS_ENDPOINT}.microcms.io/api/v1/blogs/${contentId}`
    )
    if (draftKey) endPoint.searchParams.set('draftKey', draftKey)
    axios
      .get<MicrocmsBlogResponse>(endPoint.href, {
        headers: { 'X-API-KEY': process.env.GATSBY_MICROCMS_API_KEY },
      })
      .then(res => setData(res.data))
      .catch(() => setError(true))
  }, [])

  if (error) return <NotFoundPage />
  else if (!data)
    return (
      <Layout path="Blog" meta={[{ name: 'robots', content: 'noindex' }]}>
        <LinearProgress color="secondary" />
      </Layout>
    )
  else
    return (
      <Layout
        path="Blog"
        title={data.title}
        meta={[{ name: 'robots', content: 'noindex' }]}
      >
        <BlogPost title={data.title} date={data.date} body={data.body} />
      </Layout>
    )
}

export default BlogPreviewComponent
