import React from 'react'
import { PageProps } from 'gatsby'
import BlogPreviewComponent from '../components/BlogPreviewComponent'

const BlogPreview: React.FC<PageProps> = props => {
  const params = new URLSearchParams(props.location.search)
  const contentId = params.get('contentId') ?? undefined
  const draftKey = params.get('draftKey') ?? undefined
  return <BlogPreviewComponent contentId={contentId} draftKey={draftKey} />
}

export default BlogPreview
